body {
    color: #000;
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    .container {
       .navbar {
        margin-top: 50px;
            .links {
                a {
                    text-decoration: none;
                    color: #000;
                    margin-left: 24px;
                }
                a:first-child{
                    margin-left: 0px;
                }
                
            }
            .username{
                font-size: 22px;
                margin-right:100px ;
                text-decoration: underline;
                
            }
            .logout {
                background: #a82525;
                border: 1px solid black;
                color: #ffffff;
                font-size: 18px;
                padding: 6px 10px;
                border-radius: 6px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
            }
            .logout:hover{
                background: #f33a3a;
                border: 1px solid white;
            }
        }
    
        .miqaat {
            margin-top: 50px;
            h1 {
                text-align: center;
                font-size: 50px;
            }
            .miqaat-form {
                margin-top: 50px;

                label {
                    margin-bottom: 16px;
                    font-size: 14px;
                }
                .search-bar {
                    width: 500px; // Adjust the width as needed
                    border: 1px solid #101111; // Bootstrap primary color
                    border-radius: 6px; // Rounded corners
                    padding: 10px 20px; // Padding for better spacing
                  
                    &:focus {
                      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                      border-color: #0056b3; // Darker shade of the primary color
                    }
                  
                    &::placeholder {
                      color: #6c757d; // Placeholder color from Bootstrap
                      opacity: 1; // Make placeholder fully opaque
                    }
                  }
                  .search-icon{
                    width: 40px  ;
                    height: 40px;
                    border-radius: 6px;
                    border: 1px solid black ;
                    margin-left: 10px;
                  }
                  .search-icon:hover{
                    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                      border-color: #0056b3;
                  }
                input, select {
                    border-radius: 6px;
                    border: 1px solid #000;
                    // background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    color: #615F5F;
                    font-size: 14px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    height: 42px;
                    padding: 10px 7px;
                    width: 100%;

                }
                .miqat-form-second-row {
                    margin-top: 24px;
                    
                    button {
                        margin-top: 35px;
                        border-radius: 6px;
                        border: 1px solid #000;
                        background: #A41818;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        width: 89px;
                        height: 38px;
                        color: #FFF;
                        font-size: 16px;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;

                    }
                }

            }

        }
        .seat-chart {
            margin-top: 50px;
            
            input[type=checkbox] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 15px;
                height: 15px;
                box-shadow: none !important;
                border-radius: 2px;
                border: 1px solid #000;
                padding: 0;
                background-color: #FFF;
                &:checked {
                    background-color: #0491BD;;
                }
            }
            .seat-box {
                border: 1px solid #000;
                background: #D9D9D9;
                width: 125px;
                height: 130px;
                padding-top: 5px;
                
                span {
                    display: flex;
                    justify-content: space-evenly;
                    padding-top: 25px;
                }
            }
            
        }
       .miqaatTable {
        height: 751px;
        width: 100%;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
        overflow: scroll;

            .miqaatTableHeader {
                tr{
                border-radius: 12px 12px 0px 0px;
                background-color: #D9D9D9;
                text-align: center;
                position: sticky;
                top: 0;  
                }              
                th {
                    width: 180px;
                    padding: 12px 16px;
                    background: none;
                    border-bottom: none;
                    &:first-child {
                        width: 40px;
                    }
                    &:nth-child(2) {
                        width: 390px;
                        text-align: left;
                    }
                    &:nth-child(5) {
                        width: 110px;
                    }
                }
            }
            .miqaatTableBody {
                text-align: center;
                td {
                  padding: 15px 0px;
                    

                &:nth-child(2) {
                        text-align: left;
                        padding-left: 16px;
                }
                }
            }

       }
       .seat-allocation {
        text-align: center;
        overflow: scroll;
        
        .temporary {
            border: 1px solid #000;
            background: #E1AC5C;
           
            
        }
        .permanent {
            border: 1px solid #000;
            background: #91C86F;
            
        }
        .temporary, .permanent {
            position: absolute;
            .update_button {
                // margin-top: -220px;
                padding: 0px;
                width: 17px;
                margin-left: 90px;
            }
           
            .delete_button {
                width: 17px;
                position: relative;
                bottom: 15px;
                left: 50px;
                cursor: pointer;
            } 
            // .toggle_button {
            //     width: 20px;
            //     position: relative;
            //     bottom: 17px;
            //     right: 60px;
            //     cursor: pointer;

            // } 
            .toggle_btn .form-check-input{
                width: 30px;
                height: 15px;
                border: none;
                 background-color: #91C86F; /* Background color for the unchecked state */
                 border-radius: 15px;
                 transition: background-color 0.3s;
                 position: absolute;
                 appearance: none; /* Remove default checkbox appearance */
                 cursor: pointer;
                 bottom: 5px;
                 right: 90px;

            }
            .toggle_btn .form-check-input:checked {
                background-color: #E1AC5C; /* Background color for the checked state */
              }
            .toggle_btn .form-check-input::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 2px;
                left: 2.5px;
                transition: transform 0.3s;
              }
              .toggle_btn .form-check-input:checked::before {
                transform: translateX(15px); /* Move the knob to the right */
              }
           
            .temporary-permanent-seatNum {
                padding: 0px;
                // margin-left: -9px;
                font-size: 12px;
                max-width: fit-content;
                cursor: pointer;
            }
            .comment_button{
                position: absolute;
                width: 20px;
                padding: 0px;
                max-width: fit-content;
                left: 100px;
                bottom: 100px;
                cursor: pointer;
            }
            .profile-image {
                width: 60px;
                margin-top: -15px;
                height: 72px;
            }
            .muminName-its {
                font-size: 7px;
                padding: 0px;
                padding-top: 3px;
                height: 21px;
            }
        }
        .seatChartDate {
            margin-top: 28px;
            margin-bottom: 28px;
        }
        .seatChartHdate, .seatChartEdate {
            color: #000;
            text-align: center;
            font-size: 32px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .seatChartEdate {
            margin-left: 100px;
            
        }
        .bulk-allocation-textarea{
            width: 90%;
            margin: auto;
            border-radius: 6px;
            border: 1px solid #000;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: #615F5F;
            font-size: 14px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 50px;
            padding: 10px 7px;

        }
        
        .bulkAllocationTable {
            height: 500px;
            width: 95%;
            margin: auto;
            border-radius: 12px;
            background: #FFF;
            box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
            overflow: scroll;
        
                .bulkAllocationTableHeader {
                    border-radius: 12px 12px 0px 0px;
                    background-color: #D9D9D9;
                    width: 90%;
                    margin: auto;
                    position: sticky;
                    top: 0;                
                    th {
                        width: 180px;
                        padding: 12px 16px;
                        background: none;
                        border-bottom: none;
                        &:first-child {
                            width: 40px;
                        }
                        &:nth-child(2) {
                            width: 200px;
                        }
                        &:nth-child(3) {
                            width: 300px;
                            
                        }
                        
                    }
                }
                .bulkAllocationTableBody {
                    text-align: center;
                
                }
        
           }  
        .exportExcel {
            color: #000;
            font-size: 16px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            justify-content: space-between;
        }
        .seat-count{
            display: flex;
            justify-content: center;
            gap: 2rem;
            align-items: start;
            margin-top: 1rem;
        }
        .seat-chart {   
            margin-top: 10px;
            margin-bottom: 100px;
            min-width: fit-content;
            .blocked {
                color: #FFF;
                font-size: 9px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: #000;
                pointer-events: none;
                cursor: not-allowed;

                span {
                    margin-top: 50px;
                    padding: 0 !important;
                }
            }
            .seat-box {
                position: relative;
                .pending {
                    padding: 50px;
                    padding-bottom: 0px;
                    cursor: pointer;
                }
            }
        }
        
       }
       
    }
    
    .itsTooltip {
        background-color: #FFFFFF;
        padding: 10px; 
        border-radius: 6px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
     
        .popover-search {
            width: 212px;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid #000;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
         }
            .disableClass {
            pointer-events: none;
            }
         .search-btn {
            border-radius: 6px;
            border: 1px solid #000;
            background: #A41818;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            width: 90px;
            height: 38px;
            color: #FFF;
            font-size: 16px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
                margin-left: 27px
            }
        .tooltip-body {
            span {
                font-size: 11px;
                font-weight: 500;
            }
            strong {
                font-size: 12px;
                margin-right: 7px;
                font-weight: 700;
            }
            .miqaatTableHeader {
                border-radius: 12px 12px 0px 0px;
                background-color: #D9D9D9;
                position: sticky;
                top: 0; }
            
            .mumin-image {
                width: 86px;
                height: 86px;
                margin-right: 7px;
            }
            .audio-div {
                audio {
                    width: 357px;
                    margin-bottom: 16px;
                    
                }
                span {
                    margin-top: 16px;
                    font-size: 13px;
                    font-weight: 500;
                }
    
            
         }
            .tooltip-footer-btn {
                width: 180px;
                border-radius: 6px;
                border: 1px solid #000;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 8px 0px;
                color: #FFF;
                font-size: 16px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background-color: #479C12;
                &:nth-child(2) {
                    background-color: #D18615;
                    margin-left: 16px;
                }
            
    
             }
       
        }
        
      
    }
.zakereen-submit {
            border-radius: 6px;
            border: 1px solid #000;
            background: #A41818;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            width: 90px;
            height: 38px;
            color: #FFF;
            font-size: 16px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 35px;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
  
  .form-signin .checkbox {
    font-weight: 400;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .No-data-screen {
    padding: 50px;
    border: 1px solid black;
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .filter-button {
    height: 42px;
  }
  .mumin-image {
    width: 60px;
    height: 72px;
  }
 .bulk-seat-chart .seatFooter {
    position: relative;
    bottom: 35px;
  }
 .bulk-seat-chart .seatFooter .muminGrade {
    font-size: 12px;
  }
  .main-seat-chart .seatFooter .muminGrade {
    position: relative;
    bottom: 27px;
    padding-top: 0px !important;
    font-size: 12px;
  }
}


